@import url(https://fonts.googleapis.com/css?family=Architects+Daughter|Gloria+Hallelujah|Freckle+Face|Knewave|Nanum+Brush+Script|Advent+Pro|Julius+Sans+OneMontserrat+Alternates|Orbitron|Permanent+Marker|Trade+Winds|Notable&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a:visited {

}

.streamingButton {
  position: relative;
  object-fit: contain;
  height: 100%;
  width: 25%;
}
.streamingButton img {
  left: 0;
  position: absolute;
  object-fit: contain;
  height: 100%;
  width: 100%;
}
.streamingOptions {
  width: 40%;
  height: 100%;
  right: 15px;
  top: 0px;
  position: absolute;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row;
          flex-flow: row;
  direction: rtl;
}
.artistBio {
    position: relative;
    font-size: 46px;
    text-transform: uppercase;
    width: 100%;
    height: 150px;
    text-align: center;
    text-align: center;
    top: 60px;
    display: inline-block;
    z-index: 1000;
    opacity: 0.7;
    font-family: 'Knewave';
    -webkit-animation: Delayed-Fade-In 1.5s ease-in-out;
            animation: Delayed-Fade-In 1.5s ease-in-out;
}
.artistBioSocialContainer {
  position: relative;
  left: 1px;
  top: 55px;
  width: 48px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
}
.artistBioSocial {
  width: 50%;
  height: 24px;
}
.artistAboutBio {
  text-transform: none;
  padding: 10px 15px 10px 15px;
  font-weight: 800;
  opacity: 0.8;
  width: 100%;
  text-align: center;
  font-size: 20px;
  margin: auto;
  color: black;
}

.artistLogo {
  width: 124px;
  height: 124px;
  position: absolute;
  left: 10px;
  top: 3px;
  border: 1px solid grey;
  border-radius: 64px;
  overflow: hidden;
}
.artistLogoImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.artistLogoHeader {
    object-fit: cover;
    position: absolute;
    width: 100%;
    height: 80px;
    text-align: right;
    top: 60px;
    text-align: right;
    margin: auto;
    display: inline-block;
    z-index: 1000;
    opacity: 1;
    right: 10px;
    font-family: 'Knewave';
    -webkit-animation: Delayed-Fade-In 1.5s ease-in-out;
    animation: Delayed-Fade-In 1.5s ease-in-out;
}
.artistInfo {
  position: absolute;
  width: 75%;
  height: 100px;
  right: 15px;
  top: 0px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
          flex-flow: column;
  font-size: 30px;
  top: 5px;
}
.artistHeader {
  display: inline-block;
  width: 100%;
  font-size: 26px;
  position: relative;
  text-align: right;
  font-weight: 400;
  height: 32px;
}
.artistLocation {
  display: inline-block;
  width: 100%;
  top: 3px;
  position: relative;
  text-align: right;
  font-size: 20px;
  font-weight: 100;
}
.artistGenres {
  top: 3px;
  display: inline-block;
  width: 70%;
  left: 30%;
  position: relative;
  text-align: right;
  font-size: 18px;
  direction: rtl;
}
.artistInstruments {
  top: 5px;
  width: 100%;
  position: relative;
  text-align: right;
  font-size: 13px;
}
.actMembers {
  display: inline-block;
  width: 100%;
  position: relative;
  text-align: right;
  font-size: 13px;
}
.artistMenuContainer {
  position: relative;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
          flex-flow: column;
  -webkit-animation: Delayed-Fade-In 1.5s ease-in-out;
          animation: Delayed-Fade-In 1.5s ease-in-out;
}
.artistMenuNav {
  font-family: 'Nanum Brush Script';
  position: relative;
  width: 100%;
  font-weight: 575;
  font-size: 28px;
  margin: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row;
          flex-flow: row;
}
.artistMenuNavButton {
  width: 25%;
  height: 100%;
  text-align: center;
  border-bottom: 1px solid gray;
  border-left: 1px solid gray;
  text-decoration: none;
  opacity: 0.9;
}
.artistMenuNavButton:active {
  border-color: white;
  background-color: gray;
}

.artistMenu {
    position: relative;
    font-size: 40px;
    font-weight: 400;
    padding: 0px 0px 0px 0px;
    left: 0px;
    text-transform: uppercase;
    width: 100%;
    height: 50%;
    text-align: center;
    margin:auto;
    z-index: 1000;
    opacity: 0.7;
    font-family: 'Nanum Brush Script';
}
.bgVid {
  transition: opacity 1s;
  object-position: 51.5% 50%;
  object-fit: cover;
  opacity: 0.7;
  z-index: -1000;
}

.progressBar {
  top: 30px;
  bottom: 0;
  position: absolute;
  right: 25px;
  left: 25px;
  height: 10px;
  width: auto;
  border-radius: 10px;
  border: 1px solid #FFFFFF;
}
.musicPlayerTimer {
  font-size: 18px;
}

.filler {
  background: #1DA598;
  height: 100%;
  border-radius: inherit;
  transition: width .2s ease-in;
}
.bgVid.solo {
  transition: opacity 1s;
  object-fit: cover;
  opacity: 1;
  z-index: -1000;
}
.songBox {
  font-weight: 800;
  position: relative;
  width: 100%;
  min-height: 30px;
  height: 30px;
  max-height: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row-wrap;
          flex-flow: row-wrap;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
}

.songPanel {
  transition: height 1s;
  width: 100%;
  height: 100%;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
          flex-flow: column;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-animation: Delayed-Fade-In 1.5s ease-in-out;
          animation: Delayed-Fade-In 1.5s ease-in-out;
}

.songPanel.hidden {
  transition: height 1s;
  height: 0px;
}

.songBoxImage {
  padding: 0px;
  top:0px;
  object-fit: cover;
  position: relative;
  left:0px;
  width: 36px;
  min-height: 30px;
}

.songBoxNumber
{
  position: relative;
  font-size: 24px;
  left: 0px;
  -webkit-align-content: center;
          align-content: center;
  width: 12px;
  height: 100%;
  background-color: black;
  opacity:0.7;
  color: white;
  text-align: center;
  font-family: 'Nanum Brush Script';
}

.songBoxName {
  font-family: 'Nanum Brush Script';
  font-size: 28px;
  position: relative;
  word-wrap: normal;
  height: 50%;
  left: 10px;
  font-weight: 200;
  width: 100%;
  top: 0px;
  text-align: left;
}

.soundsMenuTitle {
  text-decoration: none;
  width: 100%;
  display: inline-block;
  top: 0px;
  margin: 15px 0px 15px 0px;
}

.songBoxPlayButton {
  object-fit: cover;
  position: absolute;
  left: 58%;
  height: 32px;
  width: 10%;
  text-decoration: none;
}
.contextMenu {

}
.musicPlayer {
  transition: all 1s;
  position: absolute;
  width: 100%;
  bottom: 50px;
  height: 55px;
  text-align: center;
  background-color: #3C3C3C;
  opacity: 0.8;
  color: white;
  z-index: 3000;
}
.musicPlayer.hidden {
  transition: all 1s;
  height: 55px;
  bottom: 50px;
  background-color: #3C3C3C
}
.musicPlayer.bottom {
  transition: all 1s;
  height: 55px;
  background-color: #3C3C3C;
  bottom: 0px;
}

.musicPlayer.empty {
  height: 0px;
  bottom: 50px
}

.musicPlayerImage {
  width: 100%;
  height: 100%;
  opacity:0.8;
  position: absolute;
  left: 0px;
  object-fit: cover;
}

.musicPlayerImage.shrunk {
  width: 100%;
  height: 100%;
  opacity:0.8;
  position: absolute;
  left: 0px;
  object-fit: cover;
}

.musicPlayerInfo {
  position:absolute;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
}

.musicPlayerTitle {
  text-align: center;
  font-size: 13px;
  height: 33%;
  width: 100%;
}


.bgVid.paused {
  -webkit-animation: Shallow-Pulse infinite 7s linear;
          animation: Shallow-Pulse infinite 7s linear;
}

@-webkit-keyframes Shallow-Pulse {
  from {
    opacity: 0.7;
  }
  50% {
    opacity: 0.5;
  }
  to {
    opacity: 0.7;
  }
}

@keyframes Shallow-Pulse {
  from {
    opacity: 0.7;
  }
  50% {
    opacity: 0.5;
  }
  to {
    opacity: 0.7;
  }
}

a:visited {
  color: #303030;
}

@media only screen and (min-width : 900px) {

  html {
    margin: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    font-family: 'Amatic SC', cursive;
  }

  body {
    margin: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
  }


  /* Webkit Overrides */
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  /* General loading / splash screen */
  .loadingScreen::before {
    transition: opacity 1s;
    opacity: 0;
  }

  .loadingScreen {
    transition: opacity 1s;
    opacity: 1;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .progress {
    border: 1px solid #eee;
    height: 6px;
    border-radius: 1.5px;
    overflow: hidden;
  }

  .progress__bar {
    background-color: #eee;
    height: 4px;
  }

  /* Header */
  .siteHeader {
    position: absolute;
    top: 0;
    left: 0;
    width: 99.5vw;
    height: 75px;
    max-width: 100%;
  }
  .logoBox {
    position: absolute;
    width: 75px;
    height: 75px;
    z-index: 1000;
  }
  .logoBox > img {
    width: 100%;
    height: 100%;
  }
  .navBox {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 75px;
    z-index: 1000;
  }
  .navBox stuck {
    position:-webkit-sticky;
    position:sticky;
  }
  .navBox a {
    text-transform: uppercase;
    margin-top: 1px;
    width: 80px;
    float: left;
    color: black;
    font-weight: 600;
    text-align: center;
    padding: 10px;
    text-decoration: none;
    font-size: 14px;
    font-size-adjust: auto;
  }
  .navBox a:hover {
    background-color: #ddd;
    color: black;
  }
  .navBox a.active {
    background-color: grey;
    color: white;
  }
  .updatesPanel {
    padding-top: 70px;
    width: 99vw;
    height: 90vh;
    text-align: center;
    overflow: hidden;
  }

  /* Section Baseline (Sounds / Stories / Synthesis) */
  .sectionPanel {
    transition: opacity 1s height 1s;
    margin: 2.5vh 2vw 2.5vh 2vw;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    display: -webkit-flex;
    display: flex;
    height: 30%;
    min-height: 30%;
    max-height: 30%;
    opacity: 1;

  }
  .sectionPanel.solo {
    position: absolute;
    transition: height 2s;
    top: 80px;
    margin: 1.5vh 1vw 1.5vh 1vw;
    bottom: 10px;
    left: 10px;
    right: 10px;
    min-height: auto;
    max-height: 100vh;
    height: auto;
    width: auto;
  }

  .sectionPanel.hidden {
    transition: 1s;
    left: -100%;
    opacity: 0;
    width: 0%;
  }

  .sectionHeader {
    position: relative;
    background-color: black;
    color: white;
    width: 60px;
    margin: 0px;
    top: 0px;
    bottom: 0px;
    display: -webkit-flex;
    display: flex;
    height:100%;
    -webkit-align-items: center;
            align-items: center;
  }
  .sectionHeader.solo {
    transition: height 2s;
    position: relative;
  }
  .sectionHeaderText {
    text-align: center;
    -webkit-writing-mode: vertical-lr;
            writing-mode: vertical-lr;
    text-orientation: upright;
    text-transform: uppercase;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    position: relative;
    margin: auto;
    font-family: 'Amatic SC', cursive;
    font-size: 1.25em;
  }

  /* Search Baseline */
  .discoverBox {
    position: absolute;
    padding: 8px;
    height: 15px;
    width: 1.550px;
    margin-top: 25px;
    margin-left: 11.55px;
    background-color: white;
    box-shadow: 0px 0px 5px #000;
    color:black;
  }

  /* Spotify */
  .spotifyBox {
    height: auto;
    width: 500px;
    margin: 25px;

  }
  .spotifyBox > div {
    box-shadow: 0px 0px 5px #000;
  }
  .spotifyBox hidden {
    visibility: hidden;
  }
  .spotifyBox .image {
    width: 75px;
    height: 92px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color:black;
  }
  .spotifyBox .header {
    display: -webkit-flex;
    display: flex;
    font-size: 12px;
  }
  .spotifyBox a {
      color: white;
      text-decoration: none;
  }
  .spotifyBox .data {

  }

  /* SVG Tweaks */
  svg div .tooltip {
    margin-left: 10px;
  }

  /* Old, to be optimized
  .contentBox {
    margin-left: 140px;
    width: 91.5.5vw;
    padding-top: 100px;
    padding-left: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  */


  .now-playing__name {
    font-size: 1.5em;
    margin-bottom: 0.2em;
  }
  .now-playing__artist {
    margin-bottom: 1em;
  }
  .now-playing__status {
    margin-bottom: 1em;
  }
  .now-playing__img {
    float: left;
    margin-right: 10px;
    text-align: right;
    width: 45%;
  }
  .now-playing__img img {
    max-width: 80vmin;
    width: 100%;
  }
  .now-playing__side {
    margin-left: 5%;
    width: 45%;
  }
  /** Progress **/
  .DDDDStatsOverviewPanel {
    background-color: #1.5D1.5D1.5D;
    width: 100vw;
    padding-top: 10px;
    padding-left: 1.55px;
    height: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  .DDDDStatsOverviewPanel .DDDDPlayerStatsPanel {
    background-color: #2D2D2D;
    margin: 1.55px;
    padding: 15px;
    width: 450px;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .DDDDUsernameHeader {
    background-color: #0C0C0C;
    font-size: 24px;
    padding: 2px;
    color: #D9D9D9;
    text-align: center;
  }
  .DDDDStatsPlayerSubPanel {
    background-color: #AAAAAA;
    font-size: 18px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    width: 100%;
    text-align: center;
    margin: 0px !important;
  }
  .DDDDStatDescriptionOne {
    border: 1px solid black;
    padding: 5px 0px 5px 0px !important;
    width: 100% !important;
    height: 100% !important;
    margin: 0px !important;
  }
  .DDDDStatDescriptionTwo {
    border: 1px solid black;
    padding: 5px 0px 5px 0px !important;
    width: 50% !important;
    height: 100% !important;
    margin: 0px !important;
  }
  .DDDDStatDescriptionThree{
    border: 1px solid black;
    height: 100% !important;
    margin: 0px !important;
  }
  .DDDDStatValue {

  }

  .frontPagePanel {
    position: relative;
    min-height: 33%;
    margin: 2vh 2vw 2vh 2vw;
  }

  .COLComicButton {
    object-fit: contain;
    margin: auto;
    width: 64px;
  }

  .COLViewer {
    background-color: black;
    position: relative;
    top: 80px;
    width: 99.5vw;
    height: 150vh;
  }
  .COLControls{
    position: absolute;
    top: 5px;
    right: 400px;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    width: 50%;
    height: 64px;
    z-index: 1000;
    color: white;
  }

  .COLPage {
    background-color: white;
    position: relative;
    margin: auto;
    width: 94%;
    height: 90%;
    border: 2px solid black;
  }
  .COLFrame.fade {
    object-fit: contain;
    width: 100%;
    height: 100%;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    opacity: 0 !important;
    visibility: hidden;
  }

  .COLFrame.expand {
    width: 0%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    margin: auto;
    visibility: hidden;
  }

  .COLFrame {
    object-fit: contain;
    object-position: 50% 0%;
    width: 100%;
    height: 100%;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    opacity: 1;
    visibility: visible;
  }

  .COLSecret {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
  }

  .COLSecret .bonusText {
    visibility: hidden;
    width: 120px;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
  }

  .COLSecret .bonusText::after {
    content: ;
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }

  .COLSecret:hover .bonusText {
    visibility: visible;
    opacity: 1;
  }

  .COLCursor {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 100;
  }

  .COLCursor.lastPage {
    width: 5%;
    left: 0%;
  }

  .COLCursor.lastFrame {
    width: 45%;
    left: 5%;
  }

  .COLCursor.nextFrame {
    width: 45%;
    left: 50%;
  }

  .COLCursor.nextPage {
    width: 5%;
    left: 95%;
  }

  .highlightTitle {
    left: 0;
    right: 0;
    margin: auto;
    font-size: 1.75em;
    font-weight: 800;
    text-transform: uppercase;
    background-color: black;
    color: white;
  }

  .highlightSubtitle {
    font-size: 1.5em;
    font-weight: 500;
    background-color: #4F4F4F;
    color: white;
    text-transform: uppercase;
  }

  .highlightBody {
    margin: 0px 8px 0px 8px;
    font-size: 1.25em;
    font-weight: 300;
    background-color: white;
    text-align: left;
    height: 50%;
  }

  .highlightFrame {
    position: relative;
    height:100%;
    max-height:100%;
    width:70%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    transition: height 2s;
  }

  .highlightFrame.solo {
    transition: height 2s;
    position: absolute;
    top: 0px;
    left: 60px;
    bottom: 25px;
    height: 100%;
    width: 100%
  }

  .highlightPost {
    position: relative;
    width: 40%;
    height: 100%;
    max-height: 100%;
    /*border: 1px solid black;*/
  }

  .highlightPost.solo {
    position: relative;
    width: 25%;
    height: 25%;
  }

  .highlightImage {
    position: absolute;
    width: 25%;
    height: 100%;
    top: 0px;
    bottom: 0px;
    margin: auto;
    object-fit: contain;
    background-repeat: no-repeat;
    transition: height 0.3s;
  }

  .highlightImageM {
    position: absolute;
    width: 25%;
    height: 100%;
    top: 0px;
    bottom: 0px;
    margin: auto;
    object-fit: contain;
    background-repeat: no-repeat;
    transition: height 0.3s;
  }

  .highlightImage.solo {
    width: 75%;
  }

  .highlightImage.hovered {
    position: absolute;
    width: 25%;
    height: 150%;
    top: 0px;
    bottom: 0px;
    margin: auto;
    object-fit: contain;
    background-repeat: no-repeat;
    transition: height 0.3s;
  }

  .highlightOldPost {
      position: absolute;
      background-size: contain;
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      margin-left: 0.5%;
      object-fit: cover;
      background-color: transparent;
      overflow: hidden;
  }

  .archivePanel {
    -webkit-align-content: center;
            align-content: center;
    position: relative;
    left: 0px;
    top: 0px;
    margin: auto;
    width: 23%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    background-color: white;
  }

  .archivePanel.solo {
    position: absolute;
    left: 60px;
    height: 75%;
    top: 25%;
    width: 25%;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
  }

  .archivedPostContainer {
    -webkit-align-content: center;
            align-content: center;
    position: relative;
    left: 0px;
    top: 0px;
    margin: auto;
    width: 30%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    overflow: hidden;
  }

  .archivedPostContainer.solo {
    width: 100%;
    height: 33%;
  }

  .CataphonicStore {
    position: absolute;
    top: 80px;
    width: 100%;
    height: 100vh;
  }

  .headerBackboard {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 80px;
   }

  .spaceShip {
       position: absolute;
       width: 100%;
       left: 50px;
       object-fit: cover;
       height: 100%;
       bottom: -100px;
       -webkit-transform: scale(0.5);
               transform: scale(0.5);
       margin: auto;
       -webkit-animation: ShipFlying infinite 24s ease-in-out, Delayed-Fade-In 1.5s ease-in-out;
               animation: ShipFlying infinite 24s ease-in-out, Delayed-Fade-In 1.5s ease-in-out;;
   }
 }

@media only screen and (max-width : 899px) {

  html {
    margin: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    font-family: 'Amatic SC', cursive;
  }
  body {
    margin: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
  }
    /* Webkit Overrides */
    ::-webkit-scrollbar {
      width: 3px;
    }
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    ::-webkit-scrollbar-thumb {
      background: #888;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    /* General loading / splash screen */
    .loadingScreen {
      position: absolute;
      width: 100%;
      height: 100%;
    }
    /* Header */
    .siteHeader {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 60px;
      max-width: 100%;
      z-index: 100;
    }
    .logoBox {
      position: absolute;
      top: 5px;
      width: 50px;
      height: 50px;
      z-index: 100;
    }
    .logoBox > img {
      margin: auto;
      width: 100%;
      height: 100%;
    }
    .navBox {
      position: absolute;
      right: 60%;
      margin: auto;
      top: 0px;
      right: 0px;
      height: 100px;
      z-index: 1001;
    }
    .navBox stuck {
      position:-webkit-sticky;
      position:sticky;
    }
    .navBox a {
      text-transform: uppercase;
      margin-top: 1px;
      width: 80px;
      float: left;
      color: black;
      font-weight: 600;
      text-align: center;
      text-decoration: none;
      font-size: 14px;
      font-size-adjust: auto;
    }
    .navBox a:hover {
      background-color: #ddd;
      color: black;
    }
    .navBox a.active {
      background-color: grey;
      color: white;
    }


    .updatesPanel {
      margin-top: 60px;
      width: 100vw;
      height: 80vh;
      text-align: center;
    }

    /* Section Baseline (Sounds / Stories / Synthesis) */
    .sectionPanel {
      transition: opacity 1s;
      margin: 2.5vh 1vw 5vh 1vw;
      -webkit-flex-flow: column;
              flex-flow: column;
      display: -webkit-flex;
      display: flex;
      height: 575px;
      opacity: 1;
      position: relative;
    }
    .sectionPanel.hidden {
      transition: 1s;
      left: -100%;
      opacity: 0;
    }

    .sectionHeader {
      position: relative;
      background-color: black;
      color: white;
      width: 100%;
      margin: 0px;
      top: 0px;
      bottom: 0px;
      border-bottom: 2px solid black;
      display: -webkit-flex;
      display: flex;
      height: 40px;
      -webkit-align-items: center;
              align-items: center;
    }
    .sectionHeaderText {
      text-align: center;
      font-size: 30px;
      text-orientation: upright;
      text-transform: uppercase;
      font-weight: 800;
      height: 100%;
      position: relative;
      margin: auto;
      font-family: 'Amatic SC', cursive;
    }

    /* Search Baseline */
    .discoverBox {
      position: absolute;
      padding: 8px;
      height: 15px;
      width: 1.550px;
      margin-top: 25px;
      margin-left: 11.55px;
      background-color: white;
      box-shadow: 0px 0px 5px #000;
      color:black;
    }

    /* Spotify */
    .spotifyBox {
      height: auto;
      width: 500px;
      margin: 25px;

    }
    .spotifyBox > div {
      box-shadow: 0px 0px 5px #000;
    }
    .spotifyBox hidden {
      visibility: hidden;
    }
    .spotifyBox .image {
      width: 75px;
      height: 92px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      background-color:black;
    }
    .spotifyBox .header {
      display: -webkit-flex;
      display: flex;
      font-size: 12px;
    }
    .spotifyBox a {
        color: white;
        text-decoration: none;
    }
    .spotifyBox .data {

    }

    /* SVG Tweaks */
    svg div .tooltip {
      margin-left: 10px;
    }

    /* Old, to be optimized
    .contentBox {
      margin-left: 140px;
      width: 91.5.5vw;
      padding-top: 100px;
      padding-left: 10px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
    */


    .now-playing__name {
      font-size: 1.5em;
      margin-bottom: 0.2em;
    }
    .now-playing__artist {
      margin-bottom: 1em;
    }
    .now-playing__status {
      margin-bottom: 1em;
    }
    .now-playing__img {
      float: left;
      margin-right: 10px;
      text-align: right;
      width: 45%;
    }
    .now-playing__img img {
      max-width: 80vmin;
      width: 100%;
    }
    .now-playing__side {
      margin-left: 5%;
      width: 45%;
    }
    /** Progress **/
    .DDDDStatsOverviewPanel {
      background-color: #1.5D1.5D1.5D;
      width: 100vw;
      padding-top: 10px;
      padding-left: 1.55px;
      height: auto;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap;
    }
    .DDDDStatsOverviewPanel .DDDDPlayerStatsPanel {
      background-color: #2D2D2D;
      margin: 1.55px;
      padding: 15px;
      width: 450px;
      -webkit-justify-content: center;
              justify-content: center;
    }
    .DDDDUsernameHeader {
      background-color: #0C0C0C;
      font-size: 24px;
      padding: 2px;
      color: #D9D9D9;
      text-align: center;
    }
    .DDDDStatsPlayerSubPanel {
      background-color: #AAAAAA;
      font-size: 18px;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      width: 100%;
      text-align: center;
      margin: 0px !important;
    }
    .DDDDStatDescriptionOne {
      border: 1px solid black;
      padding: 5px 0px 5px 0px !important;
      width: 100% !important;
      height: 100% !important;
      margin: 0px !important;
    }
    .DDDDStatDescriptionTwo {
      border: 1px solid black;
      padding: 5px 0px 5px 0px !important;
      width: 50% !important;
      height: 100% !important;
      margin: 0px !important;
    }
    .DDDDStatDescriptionThree{
      border: 1px solid black;
      height: 100% !important;
      margin: 0px !important;
    }
    .DDDDStatValue {

    }

    .frontPagePanel {
      position: relative;
      min-height: 33%;
      margin: 2vh 2vw 2vh 2vw;
    }

    .COLComicButton {
      background-size: cover;
      text-align: center;
      background-repeat: no-repeat;
      width: 48px;
      height: 100%;
      margin:auto;
      bottom: 0px;
      z-index: 1001;
    }

    .COLViewer {
      top: 60px;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: black;
    }

    .COLControls {
      position: absolute;
      bottom: 0;
      text-align: center;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-flow: row wrap;
              flex-flow: row wrap;
      width: 100%;
      height: 48px;
      z-index: 1000;
      color: white;
    }

    .COLPage {
      background-color: white;
      position: relative;
      top: 5px;
      bottom: 2%;
      width: 100vw;
      height: 78%;
    }

    .COLFrame.fade {
      object-fit: contain;
      width: 100%;
      height: 100%;
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      opacity: 0 !important;
      visibility: hidden;
    }

    .COLFrame.expand {
      width: 0%;
      height: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0;
      margin: auto;
      visibility: hidden;
    }

    .COLFrame {
      object-fit: contain;
      object-position: 50% 0%;
      width: 100%;
      height: 100%;
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      opacity: 1;
      visibility: visible;
    }

    .COLSecret {
      position: relative;
      display: inline-block;
      border-bottom: 1px dotted black;
    }

    .COLSecret .bonusText {
      visibility: hidden;
      width: 120px;
      background-size: contain;
      background-repeat: no-repeat;
      background-color: #555;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 5px 0;
      position: absolute;
      z-index: 1;
      bottom: 125%;
      left: 50%;
      margin-left: -60px;
      opacity: 0;
    }

    .COLSecret .bonusText::after {
      content: ;
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #555 transparent transparent transparent;
    }

    .COLSecret:hover .bonusText {
      visibility: visible;
      opacity: 1;
    }

    .COLCursor {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 100;
    }
    .COLCursor.lastPage {
      width: 5%;
      left: 0%;
    }
    .COLCursor.lastFrame {
      width: 45%;
      left: 5%;
    }
    .COLCursor.nextFrame {
      width: 45%;
      left: 50%;
    }
    .COLCursor.nextPage {
      width: 5%;
      left: 95%;
    }
    .highlightTitle {
      left: 0;
      right: 0;
      margin: auto;
      font-size: 24px;
      font-weight: 600;
      height: 29px;
      border-bottom: 1px solid gray;
      text-transform: uppercase;
      background-color: black;
      color: white;
    }
    .highlightSubtitle {
      font-style: italic;
      font-size: 19px;
      height: 24px;
      font-weight: 500;
      border-bottom: 1px solid gray;
      background-color: #4F4F4F;
      color: white;
      text-transform: uppercase;
      -webkit-align-content: center;
              align-content: center;
    }
    .highlightBody {
      font-size: 1.25em;
      font-weight: 300;
      background-color: white;
      text-align: left;
      height: 75px;
      text-align: center;
    }
    .highlightFrame {
      position: relative;
      height:335px;
      width:100%;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-flow: row wrap;
              flex-flow: row wrap;
      overflow: hidden;
}
    .highlightPost {
      position: relative;
      width: 100%;
      height: 335px;
      max-height: 100%;
      /*border: 1px solid black;*/
    }
    .highlightImage {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 100%;
      margin: auto;
      object-fit: contain;
      background-repeat: no-repeat;
      transition: height 0.3s;
    }

    .highlightImage.hovered {
      position: absolute;
      width: 25%;
      height: 150%;
      top: 0px;
      bottom: 0px;
      margin: auto;
      object-fit: contain;
      background-repeat: no-repeat;
      transition: height 0.3s;
    }

    .archivedPostContainer {
      -webkit-align-content: center;
              align-content: center;
      position: relative;
      left: 0px;
      top: 0px;
      margin: auto;
      width: 33%;
      height: 100%;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-flow: row wrap;
              flex-flow: row wrap;
      overflow: hidden;
    }

    .highlightOldPost {
        position: absolute;
        background-size: contain;
        width: 100%;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        margin-left: 0.5%;
        object-fit: cover;
        background-color: transparent;
    }
    .archivePanel {
      -webkit-align-content: center;
              align-content: center;
      position: absolute;
      left: 0px;
      top: 375px;
      margin: auto;
      width: 100%;
      height: 200px;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-flow: row wrap;
              flex-flow: row wrap;
      background-color: white;
    }
    .CataphonicStore {
      position: absolute;
      top: 60px;
      width: 100%;
      height: 100vh;
    }
    .headerBackboard {
       position: absolute;
       top: 0;
       left: 0;
       width: 100%;
       height: 60px;
     }

     .spaceShip {
         position: absolute;
         width: 100%;
         object-fit: cover;
         height: 100%;
         left: 0px;
         margin-top: 50px;
         top: -0px;
         -webkit-animation: ShipFlying infinite 24s ease-in-out, Delayed-Fade-In 1.5s ease-in-out;
                 animation: ShipFlying infinite 24s ease-in-out, Delayed-Fade-In 1.5s ease-in-out;
     }
}

@-webkit-keyframes App-logo-spin-ccw {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}

@keyframes App-logo-spin-ccw {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}
@-webkit-keyframes App-logo-spin-cw {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes App-logo-spin-cw {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes Fade-In{
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes Fade-In{
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes Delayed-Fade-In{
  from {
    opacity: 0;
  }
  50%: {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes Delayed-Fade-In{
  from {
    opacity: 0;
  }
  50%: {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


.socialIcon {
    padding: 5px 5px 5px 5px;
    opacity: 0.6;
    position: relative;
    width: 32px;
    height: 32px;
    margin:auto;
    -webkit-animation: Delayed-Fade-In 1.5s ease-in-out;
            animation: Delayed-Fade-In 1.5s ease-in-out;
}

@media only screen and (max-height : 400px) {
  .spaceShip {
      position: absolute;
      width: 100%;
      object-fit: cover;
      height: 100%;
      bottom: 0px;
      margin-top: 0px;
      top: -0px;
      -webkit-animation: ShipFlying infinite 24s ease-in-out, Delayed-Fade-In 1.5s ease-in-out;
              animation: ShipFlying infinite 24s ease-in-out, Delayed-Fade-In 1.5s ease-in-out;;
  }
}

@-webkit-keyframes ShipFlying {
    from  { -webkit-transform: translate(100px, 75px  ) rotateZ(-35deg); transform: translate(100px, 75px  ) rotateZ(-35deg);}
    35%   { -webkit-transform: translate(50px,  50px ) rotateZ(-25deg); transform: translate(50px,  50px ) rotateZ(-25deg);}
    70%   { -webkit-transform: translate(75px,  50px ) rotateZ(-30deg); transform: translate(75px,  50px ) rotateZ(-30deg);}
    to    { -webkit-transform: translate(100px, 75px  ) rotateZ(-35deg); transform: translate(100px, 75px  ) rotateZ(-35deg);}
}

@keyframes ShipFlying {
    from  { -webkit-transform: translate(100px, 75px  ) rotateZ(-35deg); transform: translate(100px, 75px  ) rotateZ(-35deg);}
    35%   { -webkit-transform: translate(50px,  50px ) rotateZ(-25deg); transform: translate(50px,  50px ) rotateZ(-25deg);}
    70%   { -webkit-transform: translate(75px,  50px ) rotateZ(-30deg); transform: translate(75px,  50px ) rotateZ(-30deg);}
    to    { -webkit-transform: translate(100px, 75px  ) rotateZ(-35deg); transform: translate(100px, 75px  ) rotateZ(-35deg);}
}

@-webkit-keyframes Pulsate {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.1;
  }
  75% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes Pulsate {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.1;
  }
  75% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

